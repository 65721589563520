'use es6';

import { fromJS } from 'immutable';
import compose from 'transmute/compose';
import getIn from 'transmute/getIn';
import { generateUuid } from '../../util/generateUuid';
import { generateUniqueClientTimestamp } from '../../util/timestamps';
import { STATUS } from '../constants/keyPaths';
import CommonMesage from '../records/CommonMessage';
import { buildAttachments } from './buildAttachments';
import { buildStatus } from './buildStatus';
import { getId, getTimestamp } from './commonMessageFormatGetters';
import { setAttachments, setId, setStatus, setTimestamp } from './commonMessageFormatSetters';
export var buildCommonMessage = function buildCommonMessage() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var status = buildStatus(getIn(STATUS, props));
  var attachments = buildAttachments(props.attachments);
  var id = getId(props) || generateUuid();
  var timestamp = getTimestamp(props) || generateUniqueClientTimestamp('buildCommonMessage-timestamp');
  return compose(setId(id), setStatus(status), setAttachments(attachments), setTimestamp(timestamp))(CommonMesage(fromJS(props)));
};