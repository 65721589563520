'use es6';

import { toggleOpen } from '../../actions/WidgetActions';
import { trackUserInteraction } from '../../actions/trackUserInteraction';
export var handleInitialMessageBubbleClick = function handleInitialMessageBubbleClick() {
  return function (dispatch) {
    dispatch(toggleOpen({
      isOpened: true
    }));
    dispatch(trackUserInteraction());
  };
};