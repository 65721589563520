'use es6';

import { createSelector } from 'reselect';
import { getOfficeHoursStartTime } from 'conversations-internal-schema/availability/operators/availabilityGetters';
import { formatOfficeHoursWillReturnTimestamp } from '../util/formatOfficeHoursWillReturnTimestamp';
import { getWidgetAvailabilityOptions } from './getWidgetAvailabilityOptions';
export var getAvailabilityOfficeHoursWillReturnMessage = createSelector([getWidgetAvailabilityOptions], function (availabilityOptions) {
  var officeHoursStartTime = getOfficeHoursStartTime(availabilityOptions);

  if (!officeHoursStartTime) {
    return '';
  }

  return formatOfficeHoursWillReturnTimestamp(officeHoursStartTime);
});