
(function() {
  var module = {};
  var result = {};
  module.exports = { "en": { "i18n": { "duration": { "seconds": "{{ seconds }} sec", "minutes": "{{ minutes }} min", "hours": "{{ hours }} hr", "hoursAndMinutes": "{{ hours }} hr {{ minutes }} min", "days": "{{ days }} days", "shortForm": { "seconds": "{{ seconds }}s", "minutes": "{{ minutes }}m", "hours": "{{ hours }}h", "days": "{{ days }}d", "hoursAndMinutes": "{{ hours }}h {{ minutes }}m" } }, "dateTime": { "shortForm": { "months": { "january": "Jan", "february": "Feb", "march": "Mar", "april": "Apr", "may": "May", "june": "Jun", "july": "Jul", "august": "Aug", "september": "Sep", "october": "Oct", "november": "Nov", "december": "Dec" } }, "monthAndDay": "{{monthName}} {{dayNumber}}", "monthAndYear": "{{monthName}} {{yearNumber}}", "weekDayAndMonthAndDayAndYear": "{{weekDay}}, {{fullDate}}", "quarterAndYear": "Q{{quarterNumber}} {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{currencyCode}} {{amountNumberValue}}", "phoneNumberWithExtension": "{{phoneNumber}}, ext. {{extension}}" } } } };
  result.translations = module.exports;
  hns('I18n', result)
})();


