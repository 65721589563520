if (window.performance && typeof window.performance.mark === 'function') {
  window.performance.mark('mark_i18n_load_start');
}


// I18n.js
// =======
//
// This small library provides the Rails I18n API on the Javascript.
// You don't actually have to use Rails (or even Ruby) to use I18n.js.
// Just make sure you export all translations in an object like this:
//
//     I18n.translations.en = {
//       hello: "Hello World"
//     };
//
// See tests for specific formatting like numbers and dates.
//
// Orignally pulled from https://raw.github.com/fnando/i18n-js/rewrite/app/assets/javascripts/i18n.js
// v3.0.0.rc11 (commit ab4bc2a).
//
// All custom HubSpot modifications are surrounded by comments and have been
// made while the file was in this repo.
//
;(function(I18n){
  "use strict";

  // Just cache the Array#slice function.
  var slice = Array.prototype.slice;

  // Apply number padding.
  var padding = function(number) {
    return ("0" + number.toString()).substr(-2);
  };

  // Set default days/months translations.
  var DATE = {
      day_names: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    , abbr_day_names: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    , month_names: [null, "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    , abbr_month_names: [null, "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    , meridian: ["AM", "PM"]
  };

  // Set default number format.
  var NUMBER_FORMAT = {
      precision: 3
    , separator: "."
    , delimiter: ","

    // HACK, HubSpot customizations to default number format
    // (more info at https://git.hubteam.com/HubSpot/I18n/commit/32fdaf1abad25b3b937a2947a83d788603636841 and https://git.hubteam.com/HubSpot/I18n/commit/7cff88e13955e56cf0f373480f3b04e7fc8b0512)
    // It would be much nicer if we could change these defaults without having to touch i18n.js (similar to existingOptions?)

    // , strip_insignificant_zeros: false
    , strip_insignificant_zeros: true
    // END HACK
  };

  // Set default currency format.
  var CURRENCY_FORMAT = {
      unit: "$"
    , precision: 2
    , format: "%u%n"
    , sign_first: true
    , delimiter: ","
    , separator: "."
  };

  // Set default percentage format.
  var PERCENTAGE_FORMAT = {
      unit: "%"
    , precision: 3
    , format: "%n%u"
    , separator: "."
    , delimiter: ""
  };

  // Set default size units.
  var SIZE_UNITS = [null, "kb", "mb", "gb", "tb"];

  // Other default options
  var DEFAULT_OPTIONS = {
    // Set default locale. This locale will be used when fallback is enabled and
    // the translation doesn't exist in a particular locale.
      defaultLocale: "en"
    // Set the current locale to `en`.
    , locale: "en"
    // Set the translation key separator.
    , defaultSeparator: "."
    // Set the placeholder format. Accepts `{placeholder}}` and `%{placeholder}`.}

    // HACK, HubSpot accepts whitespace: `{{ placeholder }}` or `{{placeholder}}` are equivalent
    // , placeholder: /(?:\{\{|%\{)(.*?)(?:\}\}?)/gm
    , placeholder: /(?:\{\{)\s?(\S*?)\s?(?:\}\})/gm

    // Set if engine should fallback to the default locale when a translation
    // is missing.
    , fallbacks: false
    // Set the default translation object.
    , translations: {}
    // Set missing translation behavior. 'message' will display a message
    // that the translation is missing, 'guess' will try to guess the string
    , missingBehaviour: 'message'
    // if you use missingBehaviour with 'message', but want to know that the
    // string is actually missing for testing purposes, you can prefix the
    // guessed string by setting the value here. By default, no prefix!
    , missingTranslationPrefix: ''
  };

  // Custom HubSpot logic to log key usage
  var keysUsed = {};
  var fallbackKeys = [];
  var keyUsedTimeout;
  var shouldLogUsage = Math.random() <= 0.05;
  function logKeyUsage(key, usedFallbackTranslation, requestedLocaleValue) {
    var hasSetKeysUsed = false;
    function setKeysUsed(newKey) {
      if (!hasSetKeysUsed) {
        keysUsed[newKey] = keysUsed[newKey] ? keysUsed[newKey] + 1 : 1;
      }
      hasSetKeysUsed = true;
    }

    try {
      if (localStorage.getItem('TRACK_I18N_MISSING_TRANSLATIONS')) {
        setKeysUsed(key);
        var currentProject = window.hubspot.bender.currentProject;
        var currentVersion = window.hubspot.bender.currentProjectVersion;
        localStorage.setItem('I18N_KEYS_USED:' + currentProject + ':' + currentVersion, JSON.stringify(keysUsed));

        var lsAppsTracked = localStorage.getItem('I18N_APPS_TRACKED');
        var trackedApps = {};
        var shouldUpdateTrackedApps = true;
        if (lsAppsTracked) {
          trackedApps = JSON.parse(lsAppsTracked);
          if (trackedApps[currentProject] && trackedApps[currentProject].indexOf(currentVersion) < 0) {
            trackedApps[currentProject].push(currentVersion);
          } else {
            shouldUpdateTrackedApps = false;
          }
        } else {
          trackedApps[currentProject] = [currentProject];
        }
        if (shouldUpdateTrackedApps) {
          localStorage.setItem('I18N_APPS_TRACKED', JSON.stringify(trackedApps));
        }
      }

      if (usedFallbackTranslation) {
        if (localStorage.getItem('TRACK_I18N_FALLBACK_TRANSLATIONS')) {
          var i18nFallbackTranslations = localStorage.getItem('TRACK_I18N_FALLBACK_TRANSLATIONS');
          if (!i18nFallbackTranslations) {
            i18nFallbackTranslations = key;
          } else {
            i18nFallbackTranslations = i18nFallbackTranslations.concat(',', key);
          }
          localStorage.setItem('I18N_FALLBACK_TRANSLATIONS', i18nFallbackTranslations);
        }
      }
    } catch (e) {}


    if (!shouldLogUsage) {
      return;
    }
    clearTimeout(keyUsedTimeout);

    setKeysUsed(key);

    if (usedFallbackTranslation && fallbackKeys.indexOf(key) === -1) {
      fallbackKeys.push(key);
    }

    keyUsedTimeout = setTimeout(function() {
      if (window.newrelic) {
        window.newrelic.addPageAction('i18nKeysUsed', {
          keysUsed: JSON.stringify(keysUsed),
          i18nKeyCount: Object.keys(keysUsed).length,
          fallbackKeys: JSON.stringify(fallbackKeys),
          englishFallbackCount: fallbackKeys.length,
          requestedLocaleValue: requestedLocaleValue
        });
      }

      // Don't leak memory if the tab stays open forever.
      shouldLogUsage = false;
      keysUsed = {};
    }, 1000 * 60);
  }
  // End custom HubSpot Logic

  I18n.reset = function() {
    // Set default locale. This locale will be used when fallback is enabled and
    // the translation doesn't exist in a particular locale.
    this.defaultLocale = DEFAULT_OPTIONS.defaultLocale;

    // Set the current locale to `en`.
    this.locale = DEFAULT_OPTIONS.locale;

    // Set the translation key separator.
    this.defaultSeparator = DEFAULT_OPTIONS.defaultSeparator;

    // Set the placeholder format. Accepts `{{placeholder}}` and `%{placeholder}`.
    this.placeholder = DEFAULT_OPTIONS.placeholder;

    // Set if engine should fallback to the default locale when a translation
    // is missing.
    this.fallbacks = DEFAULT_OPTIONS.fallbacks;

    // Set the default translation object.
    this.translations = DEFAULT_OPTIONS.translations;

    // Set the default missing behaviour
    this.missingBehaviour = DEFAULT_OPTIONS.missingBehaviour;

    // Set the default missing string prefix for guess behaviour
    this.missingTranslationPrefix = DEFAULT_OPTIONS.missingTranslationPrefix;

  };

  // Much like `reset`, but only assign options if not already assigned
  I18n.initializeOptions = function() {
    if (typeof(this.defaultLocale) === "undefined" && this.defaultLocale !== null)
      this.defaultLocale = DEFAULT_OPTIONS.defaultLocale;

    if (typeof(this.locale) === "undefined" && this.locale !== null)
      this.locale = DEFAULT_OPTIONS.locale;

    if (typeof(this.defaultSeparator) === "undefined" && this.defaultSeparator !== null)
      this.defaultSeparator = DEFAULT_OPTIONS.defaultSeparator;

    if (typeof(this.placeholder) === "undefined" && this.placeholder !== null)
      this.placeholder = DEFAULT_OPTIONS.placeholder;

    if (typeof(this.fallbacks) === "undefined" && this.fallbacks !== null)
      this.fallbacks = DEFAULT_OPTIONS.fallbacks;

    if (typeof(this.translations) === "undefined" && this.translations !== null)
      this.translations = DEFAULT_OPTIONS.translations;
  };
  I18n.initializeOptions();

  // Return a list of all locales that must be tried before returning the
   // missing translation message. By default, this will consider the inline option,
   // current locale and fallback locale.
   //
   //     I18n.locales.get("de-DE");
   //     // ["de-DE", "de", "en"]
   //
   // You can define custom rules for any locale. Just make sure you return a array
   // containing all locales.
   //
   //     // Default the Wookie locale to English.
   //     I18n.locales["wk"] = function(locale) {
   //       return ["en"];
   //     };
   //
   I18n.locales = {};

   // Retrieve locales based on inline locale, current locale or default to
   // I18n's detection.
   I18n.locales.get = function(locale) {
     var result = this[locale] || this[I18n.locale] || this["default"];

     if (typeof(result) === "function") {
       result = result(locale);
     }

     if (result instanceof Array === false) {
       result = [result];
     }

     return result;
   };

   // The default locale list.
   I18n.locales["default"] = function(locale) {
     var locales = []
       , list = []
       , countryCode
       , count
     ;

     // Handle the inline locale option that can be provided to
     // the `I18n.t` options.
     if (locale) {
       locales.push(locale);
     }

     // Add the current locale to the list.
     if (!locale && I18n.locale) {
       locales.push(I18n.locale);
     }

     // HACK, Handle invalid Norwegian keys
     if (locale === 'noNO' || (!locale && I18n.locale === 'noNO')) {
       locales.push('no-no');
     } else if (locale === 'no-no' || (!locale && I18n.locale === 'no-no')) {
       locales.push('noNO');
     }
     // END HACK

     // Add the default locale if fallback strategy is enabled.
     if (I18n.fallbacks && I18n.defaultLocale) {
       locales.push(I18n.defaultLocale);
     }

     // Compute each locale with its country code.
     // So this will return an array containing both
     // `de-DE` and `de` locales.
     locales.forEach(function(locale){
       countryCode = locale.split("-")[0];

       if (!~list.indexOf(locale)) {
         list.push(locale);
       }

      // HubSpot Hack: Fall back lowercase locales (pt-BR to pt-br) I18n/473
      if (!~list.indexOf(locale.toLowerCase())) {
        list.push(locale.toLowerCase());
      }
      // End Hack

       if (I18n.fallbacks && countryCode && countryCode !== locale && !~list.indexOf(countryCode)) {
         list.push(countryCode);
       }
     });

     // No locales set? English it is.
     if (!locales.length) {
       locales.push("en");
     }

     return list;
   };

  // Hold pluralization rules.
  I18n.pluralization = {};

  // Return the pluralizer for a specific locale.
  // If no specify locale is found, then I18n's default will be used.
  I18n.pluralization.get = function(locale) {
    return this[locale] || this[I18n.locale] || this["default"];
  };

  // The default pluralizer rule.
  // It detects the `zero`, `one`, and `other` scopes.
  I18n.pluralization["default"] = function(count) {
    switch (count) {
      case 0: return ["zero", "other"];

      // HACK, HubSpot customization, more info at https://git.hubteam.com/HubSpot/I18n/commit/c1debccf2409a358b5456830c472ae78ece5e634#commitcomment-8149
      // case 1: return ["one"];
      case 1: return ["one", "other"];
      // END HACK

      default: return ["other"];
    }
  };

  // Return current locale. If no locale has been set, then
  // the current locale will be the default locale.
  I18n.currentLocale = function() {
    return this.locale || this.defaultLocale;
  };

  // Check if value is different than undefined and null;
  I18n.isSet = function(value) {
    return value !== undefined && value !== null;
  };

  // Find and process the translation using the provided scope and options.
  // This is used internally by some functions and should not be used as an
  // public API.
  I18n.lookup = function(scope, options) {
    options = this.prepareOptions(options);

    var locales = this.locales.get(options.locale).slice()
      , requestedLocale = locales[0]
      , locale
      , scopes
      , translations
    ;

    scope = this.getFullScope(scope, options);

    while (locales.length) {
      locale = locales.shift();
      scopes = scope.split(this.defaultSeparator);
      translations = this.translations[locale];

      if (!translations) {
        continue;
      }

      while (scopes.length) {
        translations = translations[scopes.shift()];

        if (translations === undefined || translations === null) {
          break;
        }
      }

      var getJustLangValue = function(locale) {
        return locale.split('-')[0];
      }
      var requestedLocaleValue = getJustLangValue(requestedLocale);

      if (translations !== undefined && translations !== null) {
        // HubSpot logic to track key usage and English fallback
        var translationLocaleFound = getJustLangValue(locale);
        var isNotRequestedLocale = requestedLocaleValue !== translationLocaleFound;
        var isFallbackLocale = translationLocaleFound === this.defaultLocale;
        logKeyUsage(scope, this.langEnabled && isNotRequestedLocale && isFallbackLocale, requestedLocaleValue);
        // End HubSpot logic to track English fallback
        return translations;
      }
    }

    // Custom HubSpot logic to log key usage
    logKeyUsage(scope, false, requestedLocaleValue);

    if (this.isSet(options.defaultValue)) {
      return options.defaultValue;
    }
  };

  // Rails changed the way the meridian is stored.
  // It started with `date.meridian` returning an array,
  // then it switched to `time.am` and `time.pm`.
  // This function abstracts this difference and returns
  // the correct meridian or the default value when none is provided.
  I18n.meridian = function() {
    var time = this.lookup("time");
    var date = this.lookup("date");

    if (time && time.am && time.pm) {
      return [time.am, time.pm];
    } else if (date && date.meridian) {
      return date.meridian;
    } else {
      return DATE.meridian;
    }
  };

  // Merge serveral hash options, checking if value is set before
  // overwriting any value. The precedence is from left to right.
  //
  //     I18n.prepareOptions({name: "John Doe"}, {name: "Mary Doe", role: "user"});
  //     #=> {name: "John Doe", role: "user"}
  //
  I18n.prepareOptions = function() {
    var args = slice.call(arguments)
      , options = {}
      , subject
    ;

    while (args.length) {
      subject = args.shift();

      if (typeof(subject) != "object") {
        continue;
      }

      for (var attr in subject) {
        if (!subject.hasOwnProperty(attr)) {
          continue;
        }

        if (this.isSet(options[attr])) {
          continue;
        }

        options[attr] = subject[attr];
      }
    }

    return options;
  };

  // HACK, HubSpot fix

  // Steal Underscore's isObject implementation to deal with node not correctly respecting
  // `x instanceof Object` across different requires.
  var isObject = function(obj) {
    return obj === Object(obj);
  };

  // END HACK

  // Translate the given scope with the provided options.
  I18n.translate = function(scope, options) {
    options = this.prepareOptions(options);
    var translation = this.lookup(scope, options);

    // HACK: If the translation in this locale is a non-pluralization object, try
    // falling back on the default locale. Otherwise we'd just render "[object Object]"!
    // See HubSpot/I18n#209
    if (isObject(translation) && !this.isSet(options.count)) {
      translation = this.lookup(scope, I18n.prepareOptions({locale: this.defaultLocale}, options));
    }
    // END HACK

    if (translation === undefined || translation === null) {
      return this.missingTranslation(scope, options);
    }

    // HACK: Format count param if the translation is not an object
    if (!isObject(translation) && typeof options.count === 'number') {
      options.count = I18n.formatNumber(options.count);
    }
    // END HACK

    if (typeof(translation) === "string") {
      translation = this.interpolate(translation, options);
    // HACK, HubSpot fix
    // } else if (translation instanceof Object && this.isSet(options.count)) {
    } else if (isObject(translation) && this.isSet(options.count)) {
    // END HACK
      translation = this.pluralize(options.count, translation, options);
      // HACK: Fall back on default locale if pluralization failed
      if (translation === undefined && options.locale !== this.defaultLocale) {
        return I18n.translate(scope, I18n.prepareOptions({locale: this.defaultLocale}, options));
      }
      // END HACK
    }

    return translation;
  };

  // This function interpolates the all variables in the given message.
  I18n.interpolate = function(message, options) {
    options = this.prepareOptions(options);
    var matches = message.match(this.placeholder)
      , placeholder
      , value
      , name
      , regex
    ;

    if (!matches) {
      return message;
    }

    var value;

    while (matches.length) {
      placeholder = matches.shift();
      name = placeholder.replace(this.placeholder, "$1");

      if (this.isSet(options[name])) {
        value = options[name].toString().replace(/\$/gm, "_#$#_");
      } else if (name in options) {
        // HACK: Pass options through to nullPlaceholder/missingPlaceholder
        // value = this.nullPlaceholder(placeholder, message);
        value = this.nullPlaceholder(placeholder, message, options);
      } else {
        // value = this.missingPlaceholder(placeholder, message);
        value = this.missingPlaceholder(placeholder, message, options);
      }

      regex = new RegExp(placeholder.replace(/\{/gm, "\\{").replace(/\}/gm, "\\}"));
      message = message.replace(regex, value);
    }

    return message.replace(/_#\$#_/g, "$");
  };

  // Pluralize the given scope using the `count` value.
  // The pluralized translation may have other placeholders,
  // which will be retrieved from `options`.
  I18n.pluralize = function(count, scope, options) {
    options = this.prepareOptions(options);
    var translations, pluralizer, keys, key, message;

    // HACK, HubSpot fix
    // if (scope instanceof Object) {
    if (isObject(scope)) {
      translations = scope;
    // END HACK
    } else {
      translations = this.lookup(scope, options);
    }

    if (!translations) {
      return this.missingTranslation(scope, options);
    }

    pluralizer = this.pluralization.get(options.locale);
    keys = pluralizer(count);

    while (keys.length) {
      key = keys.shift();

      if (this.isSet(translations[key])) {
        message = translations[key];
        break;
      }
    }

    // HACK, if no translation keys matched then return instead of throwing an error (#303)
    if (typeof message !== 'string') {
      return undefined;
    }
    // END HACK

    // HACK, format count to a localized string instead of just casting it
    // options.count = String(count);
    if (typeof options.count === 'number') {
      options.count = I18n.formatNumber(count);
    }
    // END HACK

    return this.interpolate(message, options);
  };

  // Return a missing translation message for the given parameters.
  I18n.missingTranslation = function(scope, options) {
    //guess intended string
    if(this.missingBehaviour == 'guess'){
      //get only the last portion of the scope
      var s = scope.split('.').slice(-1)[0];
      //replace underscore with space && camelcase with space and lowercase letter
      return (this.missingTranslationPrefix.length > 0 ? this.missingTranslationPrefix : '') +
          s.replace('_',' ').replace(/([a-z])([A-Z])/g,
          function(match, p1, p2) {return p1 + ' ' + p2.toLowerCase()} );
    }

    var fullScope           = this.getFullScope(scope, options);
    var fullScopeWithLocale = [this.currentLocale(), fullScope].join(this.defaultSeparator);

    return '[missing “' + fullScopeWithLocale + '” translation]';
  };

  // Return a missing placeholder message for given parameters
  I18n.missingPlaceholder = function(placeholder, message) {
    return "[missing " + placeholder + " value]";
  };

  I18n.nullPlaceholder = function() {
    return I18n.missingPlaceholder.apply(I18n, arguments);
  };

  // Format number using localization rules.
  // The options will be retrieved from the `number.format` scope.
  // If this isn't present, then the following options will be used:
  //
  // - `precision`: `3`
  // - `separator`: `"."`
  // - `delimiter`: `","`
  // - `strip_insignificant_zeros`: `true`     // HACK, HubSpot customization to make this default to true instead of false (more info at https://git.hubteam.com/HubSpot/I18n/commit/32fdaf1abad25b3b937a2947a83d788603636841 and https://git.hubteam.com/HubSpot/I18n/commit/7cff88e13955e56cf0f373480f3b04e7fc8b0512)
  //
  // You can also override these options by providing the `options` argument.
  //
  I18n.toNumber = function(number, options) {
    options = this.prepareOptions(
        options
      , this.lookup("number.format")
      , NUMBER_FORMAT
    );

    var negative = number < 0
      , string = Math.abs(number).toFixed(options.precision).toString()
      , parts = string.split(".")
      , precision
      , buffer = []
      , formattedNumber
      , format = options.format || "%n"
      , sign = negative ? "−" : ""
    ;

    number = parts[0];
    precision = parts[1];

    while (number.length > 0) {
      buffer.unshift(number.substr(Math.max(0, number.length - 3), 3));
      number = number.substr(0, number.length -3);
    }

    formattedNumber = buffer.join(options.delimiter);

    if (options.strip_insignificant_zeros && precision) {
      precision = precision.replace(/0+$/, "");
    }

    if (options.precision > 0 && precision) {
      formattedNumber += options.separator + precision;
    }

    if (options.sign_first) {
      format = "%s" + format;
    }
    else {
      format = format.replace("%n", "%s%n");
    }

    formattedNumber = format
      .replace("%u", options.unit)
      .replace("%n", formattedNumber)
      .replace("%s", sign)
    ;

    return formattedNumber;
  };

  // Format currency with localization rules.
  // The options will be retrieved from the `number.currency.format` and
  // `number.format` scopes, in that order.
  //
  // Any missing option will be retrieved from the `I18n.toNumber` defaults and
  // the following options:
  //
  // - `unit`: `"$"`
  // - `precision`: `2`
  // - `format`: `"%u%n"`
  // - `delimiter`: `","`
  // - `separator`: `"."`
  //
  // You can also override these options by providing the `options` argument.
  //
  I18n.toCurrency = function(number, options) {
    var customLocale = (options || {}).locale;
    options = this.prepareOptions(
        options
      , this.lookup("number.currency.format", { locale: customLocale })
      , this.lookup("number.format", { locale: customLocale })
      , CURRENCY_FORMAT
    );

    return this.toNumber(number, options);
  };

  // Localize several values.
  // You can provide the following scopes: `currency`, `number`, or `percentage`.
  // If you provide a scope that matches the `/^(date|time)/` regular expression
  // then the `value` will be converted by using the `I18n.toTime` function.
  //
  // It will default to the value's `toString` function.
  //
  I18n.localize = function(scope, value, options) {
    options || (options = {});

    switch (scope) {
      case "currency":
        return this.toCurrency(value);
      case "number":
        scope = this.lookup("number.format");
        return this.toNumber(value, scope);
      case "percentage":
        return this.toPercentage(value);
      default:
        var localizedValue;

        if (scope.match(/^(date|time)/)) {
          localizedValue = this.toTime(scope, value);
        } else {
          localizedValue = value.toString();
        }

        return this.interpolate(localizedValue, options);
    }
  };


  // Parse a given `date` string into a JavaScript Date object.
  // This function is time zone aware.
  //
  // The following string formats are recognized:
  //
  //    yyyy-mm-dd
  //    yyyy-mm-dd[ T]hh:mm::ss
  //    yyyy-mm-dd[ T]hh:mm::ss
  //    yyyy-mm-dd[ T]hh:mm::ssZ
  //    yyyy-mm-dd[ T]hh:mm::ss+0000
  //    yyyy-mm-dd[ T]hh:mm::ss+00:00
  //    yyyy-mm-dd[ T]hh:mm::ss.123Z
  //
  I18n.parseDate = function(date) {
    var matches, convertedDate, fraction;
    // we have a date, so just return it.
    if (typeof(date) == "object") {
      return date;
    };

    matches = date.toString().match(/(\d{4})-(\d{2})-(\d{2})(?:[ T](\d{2}):(\d{2}):(\d{2})([\.,]\d{1,3})?)?(Z|\+00:?00)?/);

    if (matches) {
      for (var i = 1; i <= 6; i++) {
        matches[i] = parseInt(matches[i], 10) || 0;
      }

      // month starts on 0
      matches[2] -= 1;

      fraction = matches[7] ? 1000 * ("0" + matches[7]) : null;

      if (matches[8]) {
        convertedDate = new Date(Date.UTC(matches[1], matches[2], matches[3], matches[4], matches[5], matches[6], fraction));
      } else {
        convertedDate = new Date(matches[1], matches[2], matches[3], matches[4], matches[5], matches[6], fraction);
      }
    } else if (typeof(date) == "number") {
      // UNIX timestamp
      convertedDate = new Date();
      convertedDate.setTime(date);
    } else if (date.match(/([A-Z][a-z]{2}) ([A-Z][a-z]{2}) (\d+) (\d+:\d+:\d+) ([+-]\d+) (\d+)/)) {
      // This format `Wed Jul 20 13:03:39 +0000 2011` is parsed by
      // webkit/firefox, but not by IE, so we must parse it manually.
      convertedDate = new Date();
      convertedDate.setTime(Date.parse([
        RegExp.$1, RegExp.$2, RegExp.$3, RegExp.$6, RegExp.$4, RegExp.$5
      ].join(" ")));
    } else if (date.match(/\d+ \d+:\d+:\d+ [+-]\d+ \d+/)) {
      // a valid javascript format with timezone info
      convertedDate = new Date();
      convertedDate.setTime(Date.parse(date));
    } else {
      // an arbitrary javascript string
      convertedDate = new Date();
      convertedDate.setTime(Date.parse(date));
    }

    return convertedDate;
  };


  // Formats time according to the directives in the given format string.
  // The directives begins with a percent (%) character. Any text not listed as a
  // directive will be passed through to the output string.
  //
  // The accepted formats are:
  //
  //     %a  - The abbreviated weekday name (Sun)
  //     %A  - The full weekday name (Sunday)
  //     %b  - The abbreviated month name (Jan)
  //     %B  - The full month name (January)
  //     %c  - The preferred local date and time representation
  //     %d  - Day of the month (01..31)
  //     %-d - Day of the month (1..31)
  //     %H  - Hour of the day, 24-hour clock (00..23)
  //     %-H - Hour of the day, 24-hour clock (0..23)
  //     %I  - Hour of the day, 12-hour clock (01..12)
  //     %-I - Hour of the day, 12-hour clock (1..12)
  //     %m  - Month of the year (01..12)
  //     %-m - Month of the year (1..12)
  //     %M  - Minute of the hour (00..59)
  //     %-M - Minute of the hour (0..59)
  //     %p  - Meridian indicator (AM  or  PM)
  //     %S  - Second of the minute (00..60)
  //     %-S - Second of the minute (0..60)
  //     %w  - Day of the week (Sunday is 0, 0..6)
  //     %y  - Year without a century (00..99)
  //     %-y - Year without a century (0..99)
  //     %Y  - Year with century
  //     %z  - Timezone offset (+0545)
  //
  I18n.strftime = function(date, format) {
    var options = this.lookup("date")
      , meridianOptions = I18n.meridian()
    ;

    if (!options) {
      options = {};
    }

    options = this.prepareOptions(options, DATE);

    var weekDay = date.getDay()
      , day = date.getDate()
      , year = date.getFullYear()
      , month = date.getMonth() + 1
      , hour = date.getHours()
      , hour12 = hour
      , meridian = hour > 11 ? 1 : 0
      , secs = date.getSeconds()
      , mins = date.getMinutes()
      , offset = date.getTimezoneOffset()
      , absOffsetHours = Math.floor(Math.abs(offset / 60))
      , absOffsetMinutes = Math.abs(offset) - (absOffsetHours * 60)
      , timezoneoffset = (offset > 0 ? "-" : "+") +
          (absOffsetHours.toString().length < 2 ? "0" + absOffsetHours : absOffsetHours) +
          (absOffsetMinutes.toString().length < 2 ? "0" + absOffsetMinutes : absOffsetMinutes)
    ;

    if (hour12 > 12) {
      hour12 = hour12 - 12;
    } else if (hour12 === 0) {
      hour12 = 12;
    }

    format = format.replace("%a", options.abbr_day_names[weekDay]);
    format = format.replace("%A", options.day_names[weekDay]);
    format = format.replace("%b", options.abbr_month_names[month]);
    format = format.replace("%B", options.month_names[month]);
    format = format.replace("%d", padding(day));
    format = format.replace("%e", day);
    format = format.replace("%-d", day);
    format = format.replace("%H", padding(hour));
    format = format.replace("%-H", hour);
    format = format.replace("%I", padding(hour12));
    format = format.replace("%-I", hour12);
    format = format.replace("%m", padding(month));
    format = format.replace("%-m", month);
    format = format.replace("%M", padding(mins));
    format = format.replace("%-M", mins);
    format = format.replace("%p", meridianOptions[meridian]);
    format = format.replace("%S", padding(secs));
    format = format.replace("%-S", secs);
    format = format.replace("%w", weekDay);
    format = format.replace("%y", padding(year));
    format = format.replace("%-y", padding(year).replace(/^0+/, ""));
    format = format.replace("%Y", year);
    format = format.replace("%z", timezoneoffset);

    return format;
  };

  // Convert the given dateString into a formatted date.
  I18n.toTime = function(scope, dateString) {
    var date = this.parseDate(dateString)
      , format = this.lookup(scope)
    ;

    if (date.toString().match(/invalid/i)) {
      return date.toString();
    }

    if (!format) {
      return date.toString();
    }

    return this.strftime(date, format);
  };

  // Convert a number into a formatted percentage value.
  I18n.toPercentage = function(number, options) {
    options = this.prepareOptions(
        options
      , this.lookup("number.percentage.format")
      , this.lookup("number.format")
      , PERCENTAGE_FORMAT
    );

    return this.toNumber(number, options);
  };

  // Convert a number into a readable size representation.
  I18n.toHumanSize = function(number, options) {
    var kb = 1024
      , size = number
      , iterations = 0
      , unit
      , precision
    ;

    while (size >= kb && iterations < 4) {
      size = size / kb;
      iterations += 1;
    }

    if (iterations === 0) {
      unit = this.t("number.human.storage_units.units.byte", {count: size});
      precision = 0;
    } else {
      unit = this.t("number.human.storage_units.units." + SIZE_UNITS[iterations]);
      precision = (size - Math.floor(size) === 0) ? 0 : 1;
    }

    options = this.prepareOptions(
        options
      , {unit: unit, precision: precision, format: "%n%u", delimiter: ""}
    );

    return this.toNumber(size, options);
  };

  I18n.getFullScope = function(scope, options) {
    options = this.prepareOptions(options);

    // Deal with the scope as an array.
    if (scope.constructor === Array) {
      scope = scope.join(this.defaultSeparator);
    }

    // Deal with the scope option provided through the second argument.
    //
    //    I18n.t('hello', {scope: 'greetings'});
    //
    if (options.scope) {
      scope = [options.scope, scope].join(this.defaultSeparator);
    }

    return scope;
  }

  // Set aliases, so we can save some typing.
  I18n.t = I18n.translate;
  I18n.l = I18n.localize;
  I18n.p = I18n.pluralize;

  // HACK, export default formats for use in our methods
  I18n.NUMBER_FORMAT = NUMBER_FORMAT;
  I18n.CURRENCY_FORMAT = CURRENCY_FORMAT;
  I18n.PERCENTAGE_FORMAT = PERCENTAGE_FORMAT;

// HACK, HubSpot customization so that the config can be set before the library is loaded.
// (more info at https://git.hubteam.com/HubSpot/I18n/commit/4187783fba78f9249817e16487bc0c28fa3f0557)
// })(typeof(exports) === "undefined" ? (this.I18n = {}) : exports);
})(typeof(exports) === "undefined" ? (this.I18n = this.I18n || {}) : exports);
// END HACK


"use strict";
'use es6';
'babel noModule';

(function () {
  // isNewLoaderFlag used in Baldric to ensure I18n was imported before Baldric
  I18n.isNewLoader = true;
  I18n.fallbacks = true;
  I18n.fired = {};

  if (!I18n.currencySymbols) {
    I18n.currencySymbols = {};
  }

  if (!I18n.baseLocales) {
    I18n.baseLocales = {};
  }

  if (!I18n.publicLocales) {
    I18n.publicLocales = {};
  }

  if (window && window.I18n && window.I18n.loaded) {
    console.error('I18n/init.js brought in more than once');
  }

  I18n.loaded = true; // I18n.Info promise used to figure out if we've determined the user's language already

  var infoPromise = {};
  I18n.Info = new Promise(function (resolve, reject) {
    infoPromise.resolve = resolve;
    infoPromise.reject = reject;
  });
  I18n.Info.resolve = infoPromise.resolve;
  I18n.Info.reject = infoPromise.reject;
})();

"use strict";
'use es6';
'babel noModule';

(function () {
  I18n.debugLog = function () {
    var _console;

    if (!document) {
      return;
    }

    var enabled = this.I18N_DEBUG_LOG || window.I18N_DEBUG;

    try {
      enabled = localStorage.I18N_DEBUG_LOG === 'true' || localStorage.I18N_DEBUG === 'true';
    } catch (e) {// Do nothing
    }

    if (!enabled) return;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    args.unshift('I18n:');
    /*
    # To help prevent confusion in Marketing Grader, prefix iframe if this isn't
    # the top frame on the page
    */

    if (window.parent !== window) args.unshift('(IFRAME)');

    (_console = console).log.apply(_console, args);
  };

  I18n.onAnyTrigger = function (eventName) {
    var _I18n;

    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    (_I18n = I18n).debugLog.apply(_I18n, ["triggered " + eventName].concat(args));
  };
})();

"use strict";
'use es6';
'babel noModule';

(function () {
  // SafeString type to be used to mark I18n params as _not_ needing escaping.
  // (concept borrowed from http://handlebarsjs.com/#html-escaping)
  I18n.SafeString = function SafeString(string) {
    // Work even if called without `new`
    if (!(this instanceof SafeString)) {
      return new SafeString(string);
    } else {
      this.string = string;
    }
  };

  I18n.SafeString.prototype.toString = I18n.SafeString.prototype.toHTML = function () {
    return '' + this.string;
  };
})();

"use strict";
'use es6';
'babel noModule';
/*
# If I18n is in an async <script> tag, then it may run after DOMContentLoaded
# fires. In that case, we invoke the callback after a timeout, because the
# intent of waiting for DOMContentLoaded is to wait for all of the project's
# scripts (particularly its compiled en.lyaml files) to have run, including
# those that may be later in the same <script> bundle as I18n.
#
# See discussion at https://git.hubteam.com/HubSpot/Frontend/issues/51
*/

(function () {
  I18n.whenDOMContentLoaded = function (callback) {
    if (['interactive', 'complete'].includes(document.readyState)) {
      setTimeout(callback, 1);
    } else {
      document.addEventListener('DOMContentLoaded', callback, false);
    }
  };
})();

"use strict";
'use es6';
'babel noModule';

(function () {
  var arrJoin = Array.prototype.join;

  function toRawFixed(x, minIntegerDigits, minFractionDigits, maxFractionDigits) {
    var result = Number.prototype.toFixed.call(x, maxFractionDigits);
    var intDigits = result.split('.')[0].length;
    var cut = maxFractionDigits - minFractionDigits;
    var idx = result.indexOf('e');
    var exp = idx > -1 ? result.slice(idx + 1) : 0;

    if (exp) {
      result = result.slice(0, idx).replace('.', '');
      result += arrJoin.call(Array(exp - (result.length - 1) + 1), '0') + '.' + arrJoin.call(Array(maxFractionDigits + 1), '0');
      intDigits = result.length;
    } // Repeat while cut > 0 and the last character of result is "0":


    while (cut > 0 && result.slice(-1) === '0') {
      result = result.slice(0, -1);
      cut--;
    } // If the last character of result is ".", then remove it


    if (result.slice(-1) === '.') result = result.slice(0, -1); // If int < minIntegerDigits, then pad front with zeros

    var padding;

    if (intDigits < minIntegerDigits) {
      padding = arrJoin.call(Array(minIntegerDigits - intDigits + 1), '0');
    }

    return (padding || '') + result;
  }

  I18n.util = I18n.util || {};
  I18n.util.toRawFixed = toRawFixed;
})();

"use strict";
'use es6';
'babel noModule';

(function () {
  var arrJoin = Array.prototype.join; // A function to deal with the inaccuracy of calculating log10 in pre-ES6
  // JavaScript environments. Math.log(num) / Math.LN10 was responsible for
  // causing issue #62.

  function log10Floor(n) {
    // ES6 provides the more accurate Math.log10
    if (typeof Math.log10 === 'function') {
      return Math.floor(Math.log10(n));
    }

    var x = Math.round(Math.log(n) * Math.LOG10E);
    return x - (Number('1e' + x) > n);
  }

  function toRawPrecision(x, minPrecision, maxPrecision) {
    var result,
        numDigits,
        isNegative = x < 0; // Deal with negative values later

    x = Math.abs(x); // If 0, pad out to maxPrecision 0 characters

    if (x === 0) {
      result = arrJoin.call(Array(maxPrecision + 1), '0');
      numDigits = 1;
    } else {
      // Positive values mean # of int digits and negative values mean # of fractional digits
      numDigits = log10Floor(Math.abs(x)) + 1; // Easier to get to result from here

      var factor = Math.round(Math.exp(Math.abs(numDigits - maxPrecision) * Math.LN10)); // b. Let result be the String consisting of the digits of the decimal
      //  representation of n (in order, with no leading zeroes)

      result = String(Math.round(numDigits - maxPrecision < 0 ? x * factor : x / factor));
    } // If numDigits ≥ maxPrecision, then pad the end of the number with zeros


    if (numDigits > maxPrecision) {
      result = result + arrJoin.call(Array(numDigits - maxPrecision + 1), '0');
      return isNegative ? '-' + result : result; // If numDigits = maxPrecision, then don't need to do anything
    } else if (numDigits === maxPrecision) {
      return isNegative ? '-' + result : result; // If numDigits > 0, then format as a decimal number with maxPrecision total digits
    } else if (numDigits > 0) {
      //var cutPoint = isNegative ? numDigits : numDigits + 1;
      result = result.slice(0, numDigits) + '.' + result.slice(numDigits); // If numDigits <= 0, then pad 0 zeros in front of decimal as needed
    } else if (numDigits <= 0) {
      result = '0.' + arrJoin.call(Array(-numDigits + 1), '0') + result;
    } // If result contains the character ".", and maxPrecision > minPrecision, then


    if (result.indexOf('.') >= 0 && maxPrecision > minPrecision) {
      var cut = maxPrecision - minPrecision; // Repeat while cut > 0 and the last character of result is "0":

      while (cut > 0 && result.charAt(result.length - 1) === '0') {
        result = result.slice(0, -1);
        cut--;
      } // If the last character of result is ".", then remove it


      if (result.charAt(result.length - 1) === '.') result = result.slice(0, -1);
    }

    return isNegative ? '-' + result : result;
  }

  I18n.util = I18n.util || {};
  I18n.util.log10Floor = log10Floor;
  I18n.util.toRawPrecision = toRawPrecision;
})();

"use strict";
'use es6';
'babel noModule';

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

(function () {
  var ABBREVIATE_DEFAULTS = {
    type: 'short',
    precision: 0
  }; // htmlEscape utility function based on Underscore's _.escape

  var htmlEscapeMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;'
  };
  var htmlCharPattern = '(?:&|<|>|"|\'|`)';
  var htmlTestRegexp = RegExp(htmlCharPattern);
  var htmlReplaceRegexp = RegExp(htmlCharPattern, 'g');

  var htmlReplacement = function htmlReplacement(match) {
    return htmlEscapeMap[match];
  };

  var htmlEscape = function htmlEscape(string) {
    if (htmlTestRegexp.test(string)) {
      return string.replace(htmlReplaceRegexp, htmlReplacement);
    } else {
      return string;
    }
  };

  I18n.baseI18nT = I18n.t;

  I18n.text = I18n.t = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var lastOption = args[args.length - 1];
    var includesOptions = typeof lastOption !== 'string';
    var originalOpts = includesOptions && lastOption ? args[args.length - 1] : {};
    var key = includesOptions ? args.slice(0, args.length - 1).join('.') : args.join('.');
    var modifiedOpts = {}; // Copy originalOpts to modifiedOpts, formatting numeric params and escaping others along the way

    for (var optName in originalOpts) {
      if (originalOpts.hasOwnProperty(optName)) {
        var optValue = originalOpts[optName];

        if (optValue != null) {
          modifiedOpts[optName] = I18n.formatParam(optName, optValue);
        }
      }
    }

    if (!modifiedOpts.locale && I18n.lang !== 'en' && !I18n.langEnabled && !I18n.publicPage) {
      I18n.debugLog('Forcing translation in English, lang is not enabled');
      modifiedOpts.locale = 'en';
    } // Attach the translation key to the options object for missingPlaceholder


    modifiedOpts.__scope = key; // Warn if I18n isn't ready yet (#296)

    if (I18n.fired && !I18n.fired.ready) {
      var tooEarlyError = new Error("I18n.text called before ready with key '" + key + "' - See go/i18n-help for more info");
      setTimeout(function () {
        throw tooEarlyError;
      }, 0);
    }

    return I18n.baseI18nT(key, modifiedOpts);
  }; // NOTE: re-implemented in ./react-helpers.js, keep in sync


  I18n.html = function (key, opts) {
    if (opts == null) {
      opts = {};
    }

    var gap = '';

    if (opts.useGap === true || opts.noGap === false) {
      gap = ' ';
    }

    return gap + "<i18n-string data-key='" + key + "' data-locale-at-render='" + I18n.locale + "'>" + I18n.text(key, opts) + "</i18n-string>" + gap;
  };

  I18n.formatNumber = function (val, opts) {
    if (opts == null) {
      opts = {};
    }

    if (opts.abbreviate) {
      opts = I18n.prepareOptions(opts, ABBREVIATE_DEFAULTS);

      if (typeof opts.abbreviate === 'string') {
        opts.type = opts.abbreviate;
      }

      return I18n.abbreviateNumber(val, opts);
    }

    return I18n.toNumber(val, opts);
  };

  I18n.formatPercentage = function (val, opts) {
    if (opts == null) {
      opts = {};
    }

    return I18n.toPercentage(val, opts);
  };

  I18n.formatCurrency = function (val, opts) {
    if (opts == null) {
      opts = {};
    }

    if (!I18n.currencySymbols['USD']) {
      console.error('It looks like currency data is not available. Are you using the new I18n loader and forgot to import currencies? See go/new-i18n');
    }

    if (opts.currencyCode) {
      var currencyData = I18n.currencySymbols[opts.currencyCode];

      if (currencyData) {
        if (opts.unit == null) {
          opts.unit = currencyData.symbol;
        }

        var decimalDigits = currencyData.decimal_digits;

        if (currencyData.alternative_decimal_digits && val % 1 !== 0) {
          decimalDigits = currencyData.alternative_decimal_digits;
        }

        if (opts.precision == null) {
          opts.precision = decimalDigits;
        }
      }
    }

    if (!opts.unit && !opts.useCurrencyCode) {
      console.warn('I18n: Missing or invalid currencyCode in call to formatCurrency. See https://git.hubteam.com/HubSpot/I18n/issues/59');
    }

    if (opts.abbreviate) {
      opts = I18n.prepareOptions(opts, ABBREVIATE_DEFAULTS);

      if (typeof opts.abbreviate === 'string') {
        opts.type = opts.abbreviate;
      } // Format an arbitrary number and replace it with the abbreviation, to preserve the sign/unit order


      var signedNumber = val >= 0 ? 2 : -2;
      return I18n.toCurrency(signedNumber, opts).replace('2', I18n.abbreviateNumber(Math.abs(val), opts));
    } else {
      return I18n.toCurrency(val, opts);
    }
  };

  I18n.formatSize = I18n.toHumanSize;
  var NUMERAL_OR_SIGN_OR_E = /\d|\+|-|e/i;

  I18n.parseNumber = function (string, options) {
    options = I18n.prepareOptions(options, I18n.lookup('number.format', {
      locale: (options || {}).locale
    }), I18n.NUMBER_FORMAT);

    if (typeof string === 'number') {
      return string;
    } else if (typeof string !== 'string') {
      return NaN;
    } // Remove all whitespace and replace first instance of MINUS SIGN with HYPHEN MINUS


    string = string.replace(/\s/g, '').replace('−', '-'); // Iterate through all characters in the string and return NaN if we encounter an invalid character

    var buffer = '';

    for (var _i = 0, _Array$from = Array.from(string); _i < _Array$from.length; _i++) {
      var char = _Array$from[_i];

      if (char.match(NUMERAL_OR_SIGN_OR_E)) {
        buffer += char;
      } else if (char === options.separator) {
        buffer += '.';
      } else if (char === options.delimiter) {
        continue;
      } else {
        return NaN;
      }
    } // Attempt to parse what's left


    return parseFloat(buffer);
  };

  I18n.missingNotifications = [];

  var missingNotificationWrapper = function missingNotificationWrapper(originalFunction, eventName, sendSentries, originalArgs, sentryOptions) {
    if (sentryOptions == null) {
      sentryOptions = {};
    }

    var message = originalFunction.apply(I18n, originalArgs);
    var sentryMessage = sentryOptions.message || message;

    if (I18n.missingNotifications.indexOf(sentryMessage) < 0) {
      I18n.missingNotifications.push(sentryMessage);
      var missingNoticationError = new Error("I18n: " + sentryMessage);
      var extraErrorMetadata = {
        i18nErrorType: eventName,
        currentLocale: I18n.langEnabled ? I18n.locale : 'en-us',
        translationKey: sentryOptions.translationKey,
        placeholderName: sentryOptions.placeholderName
      };

      if (window.newrelic) {
        window.newrelic.noticeError(missingNoticationError, extraErrorMetadata);
      }

      if (sendSentries && window.Raven) {
        window.Raven.captureException(missingNoticationError, {
          extra: extraErrorMetadata
        });
      }

      try {
        if (localStorage.getItem('TRACK_I18N_MISSING_TRANSLATIONS')) {
          localStorage.setItem('I18N_MISSING_TRANSLATIONS', I18n.missingNotifications);
        }
      } catch (e) {//ignore
      }
    } // Trigger event if in browser context (trigger not currently around in node/schablone)


    if (typeof I18n.trigger === 'function') {
      I18n.trigger(eventName, message);
    }

    return message;
  };

  I18n.sendSentryOnMissingTranslations = true;
  var originalMissingTranslation = I18n.missingTranslation;

  I18n.missingTranslation = function (scope) {
    var missingTranslationMessage = 'Missing translation: "' + scope + '"';
    console.warn("I18n: " + missingTranslationMessage);
    var sentryOptions = {
      translationKey: scope,
      message: missingTranslationMessage
    };
    return htmlEscape(missingNotificationWrapper(originalMissingTranslation, 'missingTranslation', I18n.sendSentryOnMissingTranslations, [scope], sentryOptions));
  };

  I18n.sendSentryOnMissingValues = true;
  var originalMissingValue = I18n.missingPlaceholder;

  I18n.missingPlaceholder = function (placeholder, message, options) {
    var missingPlaceholderMessage = "Missing placeholder: " + placeholder + " in \"" + options.__scope + "\"";
    var sentryOptions = {
      message: missingPlaceholderMessage,
      translationKey: options.__scope,
      placeholderName: placeholder
    };
    console.warn("I18n: " + missingPlaceholderMessage);
    return missingNotificationWrapper(originalMissingValue, 'missingPlaceholder', I18n.sendSentryOnMissingValues, [placeholder, message, options], sentryOptions);
  };

  if (I18n.lang == null) {
    Object.defineProperty(I18n, 'lang', {
      get: function get() {
        return I18n.locale.split('-')[0];
      }
    });
  } // Performs "oxford comma"-style list joining according to each locale's settings.
  // Based off the Rails `Array#to_sentence` function.
  //
  //    I18n.formatList([1,2,3])  # => "1, 2, and 3"
  //    I18n.formatList([1,2,3], { locale: 'ja' })  // => "1と2と3"
  //
  //    I18n.formatList([1,2], {
  //      twoWordsConnector: '  AND  '
  //    })  // => "1  AND  2"
  //
  //    I18n.formatList([1,2,3], {
  //      wordsConnector: '|'
  //    })  // => "1|2, and 3"
  //
  //    I18n.formatList([1,2,3], {
  //      wordsConnector: '|',
  //      lastWordConnector: '&&&'
  //    })  // => "1|2&&&3"
  //
  //    // And assuming that the `foo.bar` key is defined like this...
  //    //
  //    //  en:
  //    //    foo:
  //    //      bar:
  //    //        one: {{ count }} other
  //    //        other: {{ count }} others
  //
  //    I18n.formatList([1,2,3,4,5], {
  //      limit: 4,
  //      excessKey: 'foo.bar'
  //    });  // => " 1, 2, 3, and 1 other"
  //
  //    I18n.formatList([1,2,3,4,5], {
  //      limit: 3,
  //      excessKey: 'foo.bar'
  //    });  // => " 1, 2, 3, and 2 others"
  //


  var CONNECTOR_DEFAULTS = {
    inclusive: {
      wordsConnector: '|, ',
      twoWordsConnector: '| and ',
      lastWordConnector: '|, and '
    },
    exclusive: {
      wordsConnector: '|, ',
      twoWordsConnector: '| or ',
      lastWordConnector: '|, or '
    }
  };

  var interleave = function interleave(between, items) {
    var lastIndex = items.length - 1;
    return items.reduce(function (acc, item, index) {
      acc.push(item);

      if (index !== lastIndex) {
        acc.push(between);
      }

      return acc;
    }, []);
  };

  I18n.formatListArray = function (list, options) {
    var result;

    if (options == null) {
      options = {};
    }

    var _options = options,
        wordsConnector = _options.wordsConnector,
        twoWordsConnector = _options.twoWordsConnector,
        lastWordConnector = _options.lastWordConnector,
        limit = _options.limit,
        excessKey = _options.excessKey,
        exclusive = _options.exclusive;

    if (limit != null && limit <= 2) {
      throw new Error('The formatList limit must be 3 or larger');
    }

    if (limit != null && excessKey == null) {
      throw new Error('When using a limit in formatList, you must pass excessKey (that is a external, plurlized string reference)');
    }

    if (Array.isArray != null) {
      // Hack to use Array.isArray in a node envionment (since instanceof check won't work for arrays
      // created across vm/context boundaries)
      if (!Array.isArray(list)) {
        throw new Error('The first argument to I18n.formatList must be an array');
      }
    } else {
      if (!(list instanceof Array)) {
        throw new Error('The first argument to I18n.formatList must be an array');
      }
    } // Use passed options, then config for the locale, then defaults if missing


    var rootKey = 'number.human.array';
    var connectorType = 'inclusive';

    if (exclusive === true) {
      connectorType = 'exclusive';
    }

    wordsConnector = options.wordsConnector || I18n.text(rootKey + "." + connectorType + ".wordsConnector", {
      locale: options.locale
    }) || CONNECTOR_DEFAULTS[connectorType].wordsConnector;
    twoWordsConnector = options.twoWordsConnector || I18n.text(rootKey + "." + connectorType + ".twoWordsConnector", {
      locale: options.locale
    }) || CONNECTOR_DEFAULTS[connectorType].twoWordsConnector;
    lastWordConnector = options.lastWordConnector || I18n.text(rootKey + "." + connectorType + ".lastWordConnector", {
      locale: options.locale
    }) || CONNECTOR_DEFAULTS[connectorType].lastWordConnector;
    var length = list.length;

    if (length === 0) {
      return [];
    } else if (length === 1) {
      return [list[0]];
    } else if (length === 2) {
      return [list[0], twoWordsConnector, list[1]];
    } else if (limit == null || limit >= length) {
      result = interleave(wordsConnector, list.slice(0, -1));
      result.push(lastWordConnector);
      result.push(list[list.length - 1]);
      return result;
    } else {
      var excessString = I18n.text(excessKey, {
        count: list.length - limit,
        locale: options.locale
      });
      result = interleave(wordsConnector, list.slice(0, limit));
      result.push(lastWordConnector);
      result.push(excessString);
      return result;
    }
  };

  I18n.formatList = function (list, options) {
    if (options == null) {
      options = {};
    }

    return I18n.formatListArray(list, options).join('');
  };

  var ABBR_DIGIT_MAX = 15;
  var ABBR_NUMBER_MAX = Math.pow(10, ABBR_DIGIT_MAX);
  var ABBR_NUMBER_MIN = 1000; // Short number helpers (they use patterns set in `<locale>.human.abbreviated.(short|long)`)
  //
  // Standard options:
  //   - **locale**: sets the locale to use for formatting (defaults to current `I18n.locale`)
  //   - **separator**: overrides the separator—character between int and decimal digits—from the above locale
  //   - **delimiter**: overrides the delimiter—character between every 3 int digits—from the above locale
  // Advanced options (mostly borrowed from the Intl.NumberFormat spec):
  //   - **minIntegerDigits**: the minimum number of integer digits—digits to the left of the decimal—to show (default is 1). Use this to left pad integers, like `00001`
  //   - **minFractionDigits**: the minimum number of fractional digits—digits to the right of the decimal—to show (default is 0)
  //   - **maxFractionDigits**: the maximum number of fractional digits—digits to the right of the decimal—to show (default is `max(minFractionDigits, 0)`)
  //   - **minSignificantDigits**: the minimum number of any non-zersignficant digits to show (default is undefined or 1 if maxSignificantDigits is set)
  //   - **maxSignificantDigits**: the maximum number of any signficant digits to show (default is undefined or <large number> if minSignificantDigits is set)
  //   - **numDigitsToTruncateTo**: the desired number of integer digits. Cuts of the rest of the digits rounding up if needed (default is undefined)
  //   - **stripInsignificantZeros**: remove all 0 decimal digits that at end (default is true unless any min/max fraction/significant digit option is set)

  I18n.advancedFormatNumber = function (number, opts) {
    var formattedNumber;

    if (opts == null) {
      opts = {};
    }

    opts = this.prepareOptions(opts, I18n.lookup('number.format', {
      locale: opts.locale
    }), {
      separator: '.',
      delimiter: ','
    });
    var _opts = opts,
        separator = _opts.separator,
        delimiter = _opts.delimiter,
        numDigitsToTruncateTo = _opts.numDigitsToTruncateTo,
        maxThreshold = _opts.maxThreshold,
        minThreshold = _opts.minThreshold,
        minIntegerDigits = _opts.minIntegerDigits,
        maxSignificantDigits = _opts.maxSignificantDigits,
        minSignificantDigits = _opts.minSignificantDigits,
        maxFractionDigits = _opts.maxFractionDigits,
        minFractionDigits = _opts.minFractionDigits,
        stripInsignificantZeros = _opts.stripInsignificantZeros; // SET DEFAULTS

    var negative = number < 0; // Threshold for when a number should be truncated/abbreviated

    if (minThreshold == null) {
      minThreshold = Number.NEGATIVE_INFINITY;
    }

    if (maxThreshold == null) {
      maxThreshold = Number.POSITIVE_INFINITY;
    } // Default to not stripping zeros if any advanced formatting is passed


    if (minFractionDigits != null || minSignificantDigits != null || maxFractionDigits != null || maxSignificantDigits != null) {
      if (stripInsignificantZeros == null) {
        stripInsignificantZeros = false;
      }
    } else {
      if (stripInsignificantZeros == null) {
        stripInsignificantZeros = true;
      }
    } // Set higher than numDigits for zero-padded small numbers


    if (minIntegerDigits == null) {
      minIntegerDigits = Math.max(numDigitsToTruncateTo != null ? numDigitsToTruncateTo : 0, 1);
    } // Control the total number signficant digits (includes both fractional
    // and not fractional digits). No defaults unless one of the two is set


    if (minSignificantDigits != null || maxSignificantDigits != null) {
      if (maxSignificantDigits == null) {
        maxSignificantDigits = ABBR_DIGIT_MAX;
      }

      if (minSignificantDigits == null) {
        minSignificantDigits = 1;
      }
    } // Control the number of fractional digits


    if (minFractionDigits == null) {
      minFractionDigits = 0;
    }

    if (maxFractionDigits == null) {
      maxFractionDigits = Math.max(minFractionDigits, 0);
    } // CALCULATE FORMATTING


    var absNumber = Math.abs(number);

    if (numDigitsToTruncateTo != null && minThreshold <= absNumber && absNumber < maxThreshold) {
      var truncateFactor = Math.max(0, I18n.util.log10Floor(absNumber) + 1 - numDigitsToTruncateTo);
      absNumber = absNumber / Math.pow(10, truncateFactor);
    }

    if (minSignificantDigits != null && maxSignificantDigits != null) {
      formattedNumber = I18n.util.toRawPrecision(absNumber, minSignificantDigits, maxSignificantDigits);
    } else {
      formattedNumber = I18n.util.toRawFixed(absNumber, minIntegerDigits, minFractionDigits, maxFractionDigits);
    } // AND THEN LOCALIZE


    var parts = formattedNumber.split('.');
    var intPart = parts[0];
    var decPart = parts[1];
    var buffer = [];

    while (intPart.length > 0) {
      buffer.unshift(intPart.substr(Math.max(0, intPart.length - 3), 3));
      intPart = intPart.substr(0, intPart.length - 3);
    }

    var localizedNumber = buffer.join(delimiter);

    if (stripInsignificantZeros && decPart) {
      decPart = decPart.replace(/0+$/, '');
    }

    if (decPart) {
      localizedNumber += separator + decPart;
    }

    if (negative) {
      localizedNumber = "\u2212" + localizedNumber;
    }

    return localizedNumber;
  };

  var getAbbreviationKey = function getAbbreviationKey(number) {
    number = Math.abs(number);

    var zeroes = _toConsumableArray(Array(Math.floor(number).toString().length - 1).keys()).map(function () {
      return '0';
    }).join('');

    return "1" + zeroes;
  };

  var parseAbbreviatePattern = function parseAbbreviatePattern(pattern) {
    var match = pattern.match(/^([^0]*)(0+)(.*)$/);

    if (match) {
      var _match = _slicedToArray(match, 4),
          __ = _match[0],
          prefix = _match[1],
          digitString = _match[2],
          suffix = _match[3];

      var numDigits = digitString.length;
      return {
        prefix: prefix,
        digitString: digitString,
        numDigits: numDigits,
        suffix: suffix
      };
    } else {
      throw new Error("Invalid abbreviation pattern: " + pattern);
    }
  };

  I18n.abbreviateNumber = function (val, opts) {
    if (opts == null) {
      opts = {};
    }

    var _opts2 = opts,
        type = _opts2.type,
        locale = _opts2.locale,
        separator = _opts2.separator,
        delimiter = _opts2.delimiter,
        stripInsignificantZeros = _opts2.stripInsignificantZeros,
        minIntegerDigits = _opts2.minIntegerDigits,
        maxSignificantDigits = _opts2.maxSignificantDigits,
        minSignificantDigits = _opts2.minSignificantDigits,
        maxFractionDigits = _opts2.maxFractionDigits,
        minFractionDigits = _opts2.minFractionDigits;

    if (type == null) {
      type = 'short';
    } // or 'long'


    var key = getAbbreviationKey(val);
    var abbrPattern = I18n.lookup("number.human.abbreviated." + type + "." + key, opts); // Deal with pluralizing (just take the first plural pattern and use it to determine pluralization)

    if (abbrPattern != null && typeof abbrPattern === 'object') {
      var samplePatternString = abbrPattern[Object.keys(abbrPattern)[0]];
      var samplePattern = parseAbbreviatePattern(samplePatternString);
      var sampleTruncatedNumber = I18n.advancedFormatNumber(val, {
        numDigitsToTruncateTo: samplePattern.numDigits,
        minThreshold: ABBR_NUMBER_MIN,
        maxThreshold: ABBR_NUMBER_MAX
      });
      var pluralizer = I18n.pluralization.get(locale);
      var pluralKeys = pluralizer(Math.abs(sampleTruncatedNumber));

      while (pluralKeys.length) {
        var pluralKey = pluralKeys.shift();

        if (abbrPattern[pluralKey] != null) {
          abbrPattern = abbrPattern[pluralKey];
          break;
        }
      }
    } // If this locale is unable to truncate for this number size, set
    // numDigits to a high value to prevent any truncation from happening


    if (['0', 0].includes(abbrPattern) || abbrPattern == null) {
      return I18n.advancedFormatNumber(val, {
        locale: locale,
        numDigitsToTruncateTo: 30,
        minThreshold: ABBR_NUMBER_MIN,
        maxThreshold: ABBR_NUMBER_MAX,
        minIntegerDigits: minIntegerDigits != null ? minIntegerDigits : 1,
        // Need to set so it doesn't default to the large numDigitsToTruncateTo value
        minSignificantDigits: minSignificantDigits,
        maxSignificantDigits: maxSignificantDigits,
        minFractionDigits: minFractionDigits,
        maxFractionDigits: maxFractionDigits,
        separator: separator,
        delimiter: delimiter,
        stripInsignificantZeros: stripInsignificantZeros
      }); // Otherwise, truncate normally
    } else {
      var abbrFormat = parseAbbreviatePattern(abbrPattern);
      var formattedTruncatedNumber = I18n.advancedFormatNumber(val, {
        locale: locale,
        numDigitsToTruncateTo: abbrFormat.numDigits,
        minThreshold: ABBR_NUMBER_MIN,
        maxThreshold: ABBR_NUMBER_MAX,
        minIntegerDigits: minIntegerDigits,
        minSignificantDigits: minSignificantDigits,
        maxSignificantDigits: maxSignificantDigits,
        minFractionDigits: minFractionDigits,
        maxFractionDigits: maxFractionDigits,
        separator: separator,
        delimiter: delimiter,
        stripInsignificantZeros: stripInsignificantZeros
      });
      return "" + abbrFormat.prefix + formattedTruncatedNumber + abbrFormat.suffix;
    }
  }; // interpolateToArray function to translate strings while preserving React element params (#108)


  I18n.interpolateToArray = function (key, options, escapeFunction) {
    var modifiedOpts = I18n.prepareOptions(options);
    modifiedOpts.__scope = key;
    var result = [];

    if (!options.locale && !I18n.langEnabled && !I18n.publicPage) {
      I18n.debugLog('Forcing translation in English, lang is not enabled');
      modifiedOpts.locale = 'en';
    }

    var remainingMessage = I18n.lookup(key, modifiedOpts);

    if (remainingMessage == null) {
      return [I18n.missingTranslation(key)];
    }

    if (remainingMessage === Object(remainingMessage) && this.isSet(options.count)) {
      if (options.count === 0 && remainingMessage.zero) {
        remainingMessage = remainingMessage.zero;
      } else if (options.count === 1 && remainingMessage.one) {
        remainingMessage = remainingMessage.one;
      } else {
        remainingMessage = remainingMessage.other;
      }
    }

    var i = 0;
    var match = I18n.placeholder.exec(remainingMessage);

    while (match) {
      // Custom HubSpot change for default escaping of params
      var prefix = remainingMessage.substring(0, match.index);

      if (prefix !== '') {
        result.push(escapeFunction('text-chunk', prefix, i++));
      }

      var optName = match[1];
      var rawOptValue = modifiedOpts[optName] != null ? modifiedOpts[optName] : I18n.missingPlaceholder(optName, remainingMessage, modifiedOpts);
      var escapedOptValue = escapeFunction(optName, rawOptValue, i++);

      if (escapedOptValue !== '') {
        result.push(escapedOptValue);
      }

      remainingMessage = remainingMessage.substring(match.index + match[0].length); // Reset `lastIndex` because we are re-using the same regex (and it maintains state when doing `exec`)

      I18n.placeholder.lastIndex = 0;
      match = I18n.placeholder.exec(remainingMessage);
    }

    if (remainingMessage !== '') {
      result.push(escapeFunction('text-chunk', remainingMessage, i++));
    }

    return result;
  }; // formatParam function to turn SafeStrings into strings, HTML-escape string primitives, and format numbers


  I18n.formatParam = function (optName, optValue) {
    if (typeof optValue === 'number') {
      if (isNaN(optValue)) {
        return null; // don't display NaN
      }

      if (optName === 'count') {
        return Number(optValue);
      }

      if (optName.toLowerCase() === 'portalid') {
        console.warn('The use of params named "portalId" in externalized strings is deprecated. You should wrap it, like so "I18n.t(key, { portalId: I18n.SafeString(portalId) })", to prevent it from being automatically formatted. See HubSpot/I18n#109');
        return String(optValue);
      } else {
        return I18n.formatNumber(optValue);
      }
    } else if (optValue instanceof I18n.SafeString) {
      return optValue.toString();
    } else if (optValue != null) {
      return htmlEscape(String(optValue));
    } else {
      return optValue;
    }
  };

  var zeroAsOnePlural = function zeroAsOnePlural(count) {
    var validCount = count === 0 ? 1 : count;
    return I18n.pluralization.default(validCount);
  };

  I18n.pluralization.fr = zeroAsOnePlural;
})();



// set flags on I18n object for Baldric and I18n.Info







// Extra methods we're adding to I18n



