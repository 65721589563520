export var ATTACHMENTS = ['attachments'];
export var AUDIT_PARAMS = ['auditParams'];
export var CHANNEL_INSTANCE_ID = ['channelInstanceId'];
export var CLIENT_TYPE = ['clientType'];
export var CONTENT_TYPE = ['contentType'];
export var ERROR_MESSAGE = ['status', 'sendFailure', 'errorMessage'];
export var GENERIC_CHANNEL_ID = ['genericChannelId'];
export var HAS_MORE = ['hasMore'];
export var ID = ['id'];
export var IN_REPLY_TO_ID = ['inReplyToId'];
export var INTEGRATION_ID = ['integrationId'];
export var LOCALIZED_ERROR_KEY = ['status', 'sendFailure', 'localizedErrorKey'];
export var MESSAGE_DELETE_STATUS = ['messageDeletedStatus'];
export var MESSAGE_ID = ['id'];
export var MESSAGE_SEND_FAILURE = ['status', 'sendFailure'];
export var MESSAGE_STATUS = ['status', 'messageStatus'];
export var MESSAGE_STATUS_SOURCE = ['status', 'source'];
export var MESSAGE_STATUS_TIMESTAMP = ['status', 'timestamp'];
export var RECIPIENTS = ['recipients'];
export var RICH_TEXT = ['richText'];
export var SENDER = ['sender'];
export var SENDER_TYPE = ['sender', '@type'];
export var SENDERS = ['senders'];
export var STATUS = ['status'];
export var TEXT = ['text'];
export var TIMESTAMP = ['timestamp'];
export var TYPE = ['@type'];