'use es6';

import curry from 'transmute/curry';
import { indexedDataInvariant } from '../invariants/indexedDataInvariant';
import { getEntries, getEvict } from './getters';
import { deleteEntries } from './deleteEntries';
/**
 * Apply an entry eviction strategy to to IndexedAsyncData
 *
 * @param {IndexedAsyncData} indexedData IndexedAsyncData
 */

export var applyEvict = curry(function (indexedData) {
  indexedDataInvariant(indexedData);
  var evict = getEvict(indexedData);

  try {
    return deleteEntries(evict(getEntries(indexedData)), indexedData);
  } catch (error) {
    error.indexedAsyncDataName = name;
    throw error;
  }
});