'use es6';

import { eventProps } from '../selectors/eventProps';
import { getVisitorIdentity } from '../../visitor-identity/selectors/getVisitorIdentity';
import { getIsFirstVisitorSession } from '../../visitor-identity/operators/getIsFirstVisitorSession';
import { getIsPrivateLoad } from '../../widget-data/selectors/getIsPrivateLoad';
import { getUsageTracker } from '../usageTracker';
export function trackInteraction(eventName) {
  var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return function (dispatch, getState) {
    var tracker = getUsageTracker();
    var shouldNotUseTracker = getIsFirstVisitorSession(getVisitorIdentity(getState()));
    var isPrivateLoad = getIsPrivateLoad(getState());

    if (!shouldNotUseTracker) {
      tracker.track(eventName, Object.assign({}, properties, {}, eventProps(getState()), {
        privateLoad: isPrivateLoad
      }));
    }
  };
}