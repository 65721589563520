require.resolve("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./en.lyaml?sporks");

export default {
  mode: "lazy",
  source: "conversations-visitor-ui/lang",
  context: {
    "af": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./af.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-af" */); },
    "ar-eg": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./ar-eg.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-ar-eg" */); },
    "bg": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./bg.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-bg" */); },
    "bn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./bn.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-bn" */); },
    "ca-es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./ca-es.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-ca-es" */); },
    "cs": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./cs.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-cs" */); },
    "da": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./da.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-da" */); },
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./de.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-de" */); },
    "el": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./el.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-el" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./en.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-en" */); },
    "es-mx": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./es-mx.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-es-mx" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./es.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-es" */); },
    "fi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./fi.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-fi" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./fr.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-fr" */); },
    "hr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./hr.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-hr" */); },
    "hu": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./hu.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-hu" */); },
    "id": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./id.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-id" */); },
    "it": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./it.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-it" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./ja.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-ja" */); },
    "ko": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./ko.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-ko" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./nl.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-nl" */); },
    "no": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./no.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-no" */); },
    "pl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./pl.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-pl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./pt-br.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-pt-br" */); },
    "ro": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./ro.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-ro" */); },
    "ru": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./ru.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-ru" */); },
    "sl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./sl.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-sl" */); },
    "sv": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./sv.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-sv" */); },
    "th": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./th.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-th" */); },
    "tr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./tr.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-tr" */); },
    "uk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./uk.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-uk" */); },
    "vi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./vi.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-vi" */); },
    "zh-cn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./zh-cn.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-zh-cn" */); },
    "zh-hk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/asset-bender/static-1.542/node_modules/bender-lyaml-loader/lib/index.js??ref--4-oneOf-0-rules-12!./zh-hk.lyaml?sporks" /* webpackChunkName: "conversations-visitor-ui-lang-zh-hk" */); }  }
};
