'use es6';

import pipe from 'transmute/pipe';
import { SYSTEM } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { LIVE_CHAT } from 'conversations-internal-schema/constants/ThreadSources';
import OfficeHoursMessage from 'conversations-message-history/office-hours-message/records/OfficeHoursMessage';
import { getOfficeHoursStartTime } from 'conversations-internal-schema/availability/operators/availabilityGetters';
import { publishMessageToConversation } from '../../actions/PublishActions/publishMessageToConversation';
import { getWidgetAvailabilityOptions } from '../../availability/selectors/getWidgetAvailabilityOptions';
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
import { getAvailabilityOfficeHoursWillReturnMessage } from '../../availability/selectors/getAvailabilityOfficeHoursWillReturnMessage';
import { generateUuid } from 'conversations-message-history/util/generateUuid';
import { SENT } from 'conversations-message-history/common-message-format/constants/statusTypes';
export var publishOfficeHoursMessage = function publishOfficeHoursMessage(_ref) {
  var channel = _ref.channel,
      threadId = _ref.threadId;
  return function (dispatch, getState) {
    var senderType = SYSTEM;
    var message = new OfficeHoursMessage({
      id: generateUuid(),
      text: getAvailabilityOfficeHoursWillReturnMessage(getState()),
      officeHoursStartTime: pipe(getWidgetAvailabilityOptions, getOfficeHoursStartTime)(getState()),
      sender: {
        '@type': senderType
      },
      status: {
        source: LIVE_CHAT,
        messageStatus: SENT
      }
    });
    dispatch(publishMessageToConversation({
      channel: channel,
      message: message,
      threadId: threadId
    }));
    dispatch(trackInteraction('widget-interaction', {
      action: 'view return time'
    }));
  };
};