'use es6';

import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import PropTypes from 'prop-types';
import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
export var commonPropTypes = {
  message: PropTypes.string.isRequired,
  options: PropTypes.object,
  useGap: PropTypes.bool
};
export var I18nCommonMixin = {
  getDefaultProps: function getDefaultProps() {
    return {
      options: {},
      useGap: false
    };
  },
  _getValue: function _getValue() {
    var unescapeOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var _this$props = this.props,
        useGap = _this$props.useGap,
        message = _this$props.message,
        options = _this$props.options;
    var value = unescapeOptions ? // i18n-lint-describe-next-line key-is-argument
    unescapedText(message, options) : // i18n-lint-describe-next-line key-is-argument
    I18n.text(message, options);

    if (useGap === true) {
      value = " " + value + " ";
    }

    return value;
  },
  statics: {
    isI18nElement: true
  }
};
export var getPassThroughProps = function getPassThroughProps(allProps) {
  var localeOverwrite = allProps.options && allProps.options.locale;
  var result = {
    'data-locale-at-render': localeOverwrite || I18n.locale,
    'data-key': allProps.message
  };

  for (var _i = 0, _Object$keys = Object.keys(allProps); _i < _Object$keys.length; _i++) {
    var propKey = _Object$keys[_i];

    if (['message', 'options', 'useGap'].indexOf(propKey) < 0) {
      result[propKey] = allProps[propKey];
    }
  }

  return result;
};
export var classNameFix = function classNameFix(props) {
  var className = props.className,
      classProp = props.class,
      restProps = _objectWithoutProperties(props, ["className", "class"]);

  if (className && !classProp) {
    return Object.assign({
      class: className
    }, restProps);
  }

  return props;
};