'use es6';

import I18n from 'I18n';
import { timestampIsToday } from './timestampIsToday';
import { timestampIsTomorrow } from './timestampIsTomorrow';
export var formatOfficeHoursWillReturnTimestamp = function formatOfficeHoursWillReturnTimestamp(timestamp) {
  var time = I18n.moment.userTz(timestamp);
  var formattedTime = time.format('LT');

  if (timestampIsToday(timestamp)) {
    return I18n.text('conversationsVisitorUIAvailability.sameDay', {
      time: formattedTime
    });
  }

  if (timestampIsTomorrow(timestamp)) {
    return I18n.text('conversationsVisitorUIAvailability.nextDay', {
      time: formattedTime
    });
  }

  return I18n.text('conversationsVisitorUIAvailability.nextWeek', {
    dayOfWeek: I18n.moment.weekdays(true, time.weekday()),
    time: formattedTime
  });
};