export var AWAY_IN_OFFCE_HOURS_STRATEGY = ['awayInOfficeHoursStrategy'];
export var AWAY_MESSAGE = ['awayMessage'];
export var OFFICE_HOURS = ['officeHours'];
export var OUTSIDE_OFFICE_HOURS_MESSAGE = ['outsideOfficeHoursMessage'];
export var OUTSIDE_OFFICE_HOURS_STRATEGY = ['outsideOfficeHoursStrategy'];
export var TEAM_MEMBERS_AVAILABILITY_STRATEGY = ['teamMembersAvailabilityStrategy'];
export var TYPICAL_RESPONSE_TIME = ['typicalResponseTime'];
export var AVAILABILITY_STRATEGY = ['availabilityStrategy'];
export var AVAILABILITY_STRATEGY_CONFIG = ['availabilityStrategyConfig'];
export var AVAILABILITY_STRATEGY_CONFIG_AWAY_IN_OFFICE_HOURS_STRATEGY = ['availabilityStrategyConfig', 'awayInOfficeHoursStrategy'];
export var AVAILABILITY_STRATEGY_CONFIG_AWAY_MESSAGE = ['availabilityStrategyConfig', 'awayMessage'];
export var AVAILABILITY_STRATEGY_CONFIG_OUTSIDE_OFFICE_HOURS_MESSAGE = ['availabilityStrategyConfig', 'outsideOfficeHoursMessage'];
export var AVAILABILITY_STRATEGY_CONFIG_OUTSIDE_OFFICE_HOURS_STRATEGY = ['availabilityStrategyConfig', 'outsideOfficeHoursStrategy'];
export var AVAILABILITY_STRATEGY_CONFIG_TEAM_MEMBERS_AVAILABILITY_STRATEGY = ['availabilityStrategyConfig', 'teamMembersAvailabilityStrategy'];
export var AVAILABILITY_STRATEGY_CONFIG_TYPICAL_RESPONSE_TIME = ['availabilityStrategyConfig', 'typicalResponseTime'];