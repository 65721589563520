'use es6';

import { getMessageIsApproximatelyFromCurrentSession } from 'conversations-message-history/thread-history/operators/getMessageIsApproximatelyFromCurrentSession';
import { isOfficeHoursMessage } from 'conversations-message-history/office-hours-message/operators/isOfficeHoursMessage';
import { hasMessages } from 'conversations-message-history/thread-history/operators/hasMessages';
import { getMessages } from 'conversations-message-history/thread-history/operators/getMessages';
export var hasOfficeHoursMessageFromCurrentSession = function hasOfficeHoursMessageFromCurrentSession(threadHistory) {
  if (!hasMessages(threadHistory)) {
    return false;
  }

  var messages = getMessages(threadHistory);
  var lastOfficeHoursMessage = messages.findLast(isOfficeHoursMessage);
  return !!(lastOfficeHoursMessage && getMessageIsApproximatelyFromCurrentSession(lastOfficeHoursMessage));
};