'use es6';

import '../../moment/moment-and-timezone-init';
import { momentLocaleMapper, momentMappings, defaultLanguage, getLangEnabledLocales } from '../../internal/legacyI18nInit'; // To do: We currently have to point to an arbitrary locale.
// Need extra lazy loader

import momentLocale from 'i2l?mode=very-lazy&query=sporks!moment/locale/es';
export default (function (Provider) {
  return Provider.register(momentLocale, {
    localeMapper: momentLocaleMapper,
    getLocales: getLangEnabledLocales
  }).then(function () {
    var validMomentLocale = momentMappings[I18n.locale] || momentMappings[I18n.locale.substring(0, 2)] || defaultLanguage;
    return I18n.moment.locale(validMomentLocale);
  });
});